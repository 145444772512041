
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "SystemAlertTypeSelect",
  props: {
    system_alert_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:system_alert_type_id", "changeShippedVias"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const inputAlertType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isAlertTypeRequired = (value) => {
      if (props.required && !value) {
        return t("ralerttype");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("system_alert_type_id", isAlertTypeRequired);

    const getShippedVias = () => {
      ApiService.get("/api/system_alert_type").then(({ data }) => {
        inputAlertType.value.list = data.system_alert_types;
        inputAlertType.value.options = data.system_alert_types;
      });
    };
    const selectAlertType = (query) => {
      if (query !== "") {
        inputAlertType.value.loading = true;
        setTimeout(() => {
          inputAlertType.value.loading = false;
          inputAlertType.value.options = inputAlertType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputAlertType.value.options.length) {
            ApiService.query(`/api/system_alert_type`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputAlertType.value.list = data.system_alert_types;
              inputAlertType.value.options = data.system_alert_types;
            });
          }
        }, 200);
      } else {
        inputAlertType.value.options = [];
      }
    };

    watch(
      () => props.system_alert_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    setTimeout(() => {
      getShippedVias();
    }, 3000);

    return {
      element_id,
      inputAlertType,
      errorMessage,
      meta,
      getShippedVias,
      selectAlertType,
    };
  },
};
