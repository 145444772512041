
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import SystemAlertTypeSelect from "@/components/catalogs-select/SystemAlertTypeSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default {
  name: "SystemAlert",
  components: {
    CardBasicNew,
    TableBasicSelect,
    SystemAlertTypeSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const header = ref([
      { column: t("system_alert_type_id"), key: "system_alert_type_id" },
      { column: t("iname"), key: "iname" },
      {
        column: t("idescription"),
        key: "idescription",
      },
    ]);
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "system alert: create"
        ) !== -1
      );
    });
    const canSeeDetails = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "system alert: show") !==
        -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "system alert: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "system alert: delete"
        ) !== -1
      );
    });

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.value.user_preferences.view_type_items);
    const tableButtons = ref([]);
    const originalData = ref([]);
    const system_alert_type_id = ref([]) as any;
    const name = ref("");
    const description = ref("");
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
      last_page: 1,
    });

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      ApiService.delete(`/api/system_alert/${deleteId.value}`).then(
        ({ data }) => {
          store.commit("setDeleteId", undefined);
          showDelete.value = false;
          serverSearch();
        }
      );
    };

    const updateData = (items: any) => {
      originalData.value = items;
      dataList.value = createFieldItems(items);
      filtered.value =
        viewType.value === "card" ? dataList.value : createTableItems(items);
      tableButtons.value = [
        {
          name: "Editar" as never,
          type: "EDIT" as never,
          color: "primary" as never,
        } as never,
      ];
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const serverSearch = () => {
      ApiService.query(`/api/system_alert`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          system_system_alert_id: system_alert_type_id.value,
          name: name.value,
          description: description.value,
        },
      }).then(({ data }) => {
        updateData(data.system_alerts);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
      });
    };

    const createFieldItems = (data) => {
      const add_fields = data.map(function (element) {
        element.items = {
          id: element.id,
          system_alert_type_id: element.system_alert_type.name,
          iname: element.name,
          idescription: element.description,
        };
        return element;
      });
      return add_fields;
    };

    const createElement = () => {
      router.push({ name: "systemAlertType" });
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          system_alert_type_id: element.system_alert_type.name,
          iname: element.name,
          idescription: element.description,
        });
      });
      return result;
    };

    watch(
      () => viewType.value,
      (first) => {
        if (first) {
          filtered.value =
            first === "card"
              ? createFieldItems(originalData.value)
              : createTableItems(originalData.value);
          pagination.value.total = filtered.value.length;
          pagination.value.last_page = Math.floor(filtered.value.length / 12);
        }
      }
    );

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push({ name: "systemAlertEdit", params: { id: param1 } });
      } else if (param2 === "REMOVE") {
        deleteDialog(param3);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/forwarder/system_alerts", label: "tsystemalert" },
      ]);
      serverSearch();
    });

    onUpdated(() => {
      console.clear();
    });

    return {
      header,
      search,
      permissions,
      canCreate,
      canSeeDetails,
      canEdit,
      canDelete,
      showDelete,
      deleteId,
      dataList,
      filtered,
      viewType,
      tableButtons,
      originalData,
      pagination,
      name,
      system_alert_type_id,
      description,
      formatDate,
      deleteDialog,
      deleteElement,
      currentPageChange,
      setItemsPerPage,
      onSearch,
      serverSearch,
      createElement,
      createTableItems,
      actionsButton,
    };
  },
};
